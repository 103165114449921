import { Redirect, useLocation } from 'react-router-dom';

import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonButton, IonGrid, IonRow, IonCol } from '@ionic/react';

import classes from './Redirect.module.css';

import { Helmet } from 'react-helmet';
import { useContext, useEffect, useState } from 'react';
import AppContext from '../components/core/AppContext';

function RedirectPage() {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const appCtx = useContext(AppContext);

	const getSafeUrl = (url) => {
		url = encodeURI(url);
		const replacementMap = {
			'#': encodeURIComponent('#'),
		};
		Object.entries(replacementMap).forEach(([key, value]) => {
			url = url.replaceAll(key, value);
		});
		return url;
	};

	const url = (searchParams.get('url') || '').trim();
	const btnTitle = (searchParams.get('title') || '').trim();
	const immediate = (searchParams.get('immediate') || '').trim();

	const [ count, setCount ] = useState(immediate ? 0 : 5);

	const relatedVideos = appCtx.getVideoBySourceURL(url);

	const title = relatedVideos.map((video) => video.title).join(' -- ') || 'Unknown';

	function redirectButtonHandler() {
		window.dataLayer.push({
			event: 'source_code_download',
			'Source Code URL': url,
			'Video Title': title
		});
	}

	useEffect(() => {
		if (count === 0) return;

		const intervalId = setInterval(() => setCount(count - 1), 1000);

		return () => clearInterval(intervalId);
	});

	if (url === '') return <Redirect to="/" />;

	// if (counter === 0) return <Redirect to={url} />;

	return (
		<div>
			<Helmet>
				<title>BasselTech - Redirect</title>
				<meta name="description" content="BasselTech Redirection Page" />
			</Helmet>
			<IonCard>
				<IonCardHeader>
					<IonCardTitle>Redirecting you ...</IonCardTitle>
				</IonCardHeader>
				<IonCardContent className="ion-text-center">
					<IonGrid>
						<IonRow>
							<IonCol size="3"/>
							<IonCol size="3"/>
							<IonCol size="3"/>
							<IonCol size="3"/>
						</IonRow>
						<IonRow>
							<IonCol size="12">
								{
									count > 0 ? (
										<div className={classes.counter} id="counterContainer">
											<span id="counter">{count}</span>
										</div>
									) : (
										<IonButton
											href={getSafeUrl(url)}
											target="_blank"
											onClick={() => !immediate && redirectButtonHandler()}
										>
											{btnTitle || 'Go To Page'}
										</IonButton>
									)
								}
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="3"/>
							<IonCol size="3"/>
							<IonCol size="3"/>
							<IonCol size="3"/>
						</IonRow>
					</IonGrid>
				</IonCardContent>
			</IonCard>
		</div>
	);
}

export default RedirectPage;
